<template>
  <section class="body">
    <section class="banner">
      <div class="wrap">
        <h2><strong>流动式智能健康服务车</strong>(X2版)</h2>
        <p>智能化核酸采样、送样车，减少风险接触，提升防护</p>
        <img src="../../assets/v3/sampling/car_X2.png" width="1190" height="533" class="img" >

        <div class="brag">
          <ul class="list">
            <li>
              <span class="icon">
                <img src="../../assets/v3/sampling/svg/icon_1.svg" width="28" height="28" />
              </span>
              <span class="txt">机动采样</span>
            </li>
            <li>
              <span class="icon">
                <img src="../../assets/v3/sampling/svg/icon_2.svg" width="28" height="28" />
              </span>
              <span class="txt">正压防护</span>
            </li>
            <li>
              <span class="icon">
                <img src="../../assets/v3/sampling/svg/icon_3.svg" width="28" height="28" />
              </span>
              <span class="txt">粒子消杀 <sup>1</sup></span>
            </li>
            <li>
              <span class="icon">
                <img src="../../assets/v3/sampling/svg/icon_4.svg" width="28" height="28" />
              </span>
              <span class="txt">无接触采样</span>
            </li>
            <li>
              <span class="icon">
                <img src="../../assets/v3/sampling/svg/icon_5.svg" width="28" height="28" />
              </span>
              <span class="txt">SVaas方案</span>
            </li>
            <li>
              <span class="icon">
                <img src="../../assets/v3/sampling/svg/icon_6.svg" width="28" height="28" />
              </span>
              <span class="txt">粒子风幕 <sup>2</sup></span>
            </li>
            <li>
              <span class="icon">
                <img src="../../assets/v3/sampling/svg/icon_7.svg" width="28" height="28" />
              </span>
              <span class="txt">UV消杀</span>
            </li>
          </ul>
          <ol class="tips">
            <li>中科醒诺高能粒子隧道消杀技术</li>
            <li>采样窗口高能粒子隧道隔绝保护屏障</li>
          </ol>
        </div>
      </div>
    </section>

    <section class="design">
      <h3 class="title"><strong>智能健康服务车1.1版</strong> 设计方案</h3>
      <img class="img d_1" src="../../assets/v3/sampling/design_X2_1.png" width="912" height="523" />
      <img class="img d_2" src="../../assets/v3/sampling/design_X2_2.png" width="889" height="420" />
    </section>

  </section>
</template>

<script>
export default {
  name: "SamplingX2",
};
</script>

<style lang="scss" scoped>
.banner {
  margin-bottom: 254px;
  font-size: 30px;
  color: #fff;
  line-height: 1.2;
  background-color: #E83E0B;

  .wrap {
    position: relative;
    top: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    margin-bottom: 10px;
    color: #fff;
    font-size: 80px;
    line-height: 1.2;

    strong {
      font-weight: bold;
    }
  }

  .img {
    position: relative;
    top: 36px;
    max-width: 100%;
    width: 1268px;
    height: auto;
  }

  .brag {
    position: relative;
    top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    color: #747A79;

    .list {
      margin-bottom: 28px;
      display: flex;
      color: #539B8A;

      li {
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: rgba($color: #00A684, $alpha: 0.1);
        }
      }
    }

    sup {
      font-size: 12px;
    }
    
    .tips {
      display: flex;
      list-style: decimal inside;

      li {
        padding: 0 10px;
      }
    }
  }
}

.design {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin: 0 auto;
  padding: 160px 80px;
  height: 1080px;
  max-width: 1540px;

  .title {
    padding-top: 156px;
    padding-left: 34px;
    font-size: 30px;
    line-height: 1;
    border-left: 22px solid #20C67F;
  }

  .img {
    position: absolute;

    &.d_1 {
      top: 150px;
      left: 0;
    }

    &.d_2 {
      right: 0;
      bottom: 110px;
    }
  }
}


@media only screen and (max-width: 500px) {
  .banner {
    margin-bottom: 280px;
    font-size: 14px;
    line-height: 24px;
    
    .wrap {
      top: 160px;
      padding-bottom: 110px;
    }

    .logo {
      width: 195px;
      height: auto;
    }

    h2 {
      margin-bottom: 4px;
      font-size: 24px;
      line-height: 36px;
    }

    .img {
      top: 0;
      margin-top: 30px;
      width: 100%;
      height: auto;
    }

    .brag {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: -110px;
      width: 100%;

      .list {
        margin-bottom: 12px;
        padding: 0 16px;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 12px;
        line-height: 16px;

        li {
          margin: 0 12px 12px;

          .icon {
            margin-bottom: 8px;
            width: 36px;
            height: 36px;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      sup {
        font-size: 10px;
      }

      .tips {
        display: block;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }

  .design {
    padding: 0 12px 60px;
    flex-direction: column;
    align-items: flex-start;
    height: auto;

    .title {
      margin-bottom: 24px;
      margin-left: -12px;
      padding-top: 40px;
      padding-left: 12px;
      font-size: 24px;
      border-left: 6px solid #20C67F;
    }

    .img {
      position: static;
      width: 100%;
      height: auto;
    }
  }
}
</style>